export default class BookingMC extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: "https://earth.us6.list-manage.com/subscribe/post?u=4761424fd297cd4c0d761c262&amp;id=d34104769a",
			messages:
				{
					sending: "Sending...",
					success: "You have successfully booked your visit. See you in the Majlis!",
					error: "An unexpected internal error has occurred.",
					empty: "You must write an e-mail.",
					duplicate: "You have successfully booked your visit. See you in the Majlis!",
					button: "Book"
				},
			fields:
				[
					{
						name: 'DATE',
						label: 'Date',
						type: 'futureDateInput',
						days: '7',
						required: true,
					},
					{
						name: 'TIMESLOT',
						label: 'Time',
						type: 'timeslotInput',
						required: true,
						start: 11,
						end: 18,
					},
					{
						name: 'EMAIL',
						label: 'E-mail',
						// type: 'text',
						required: true,
					},
				]
		};
	}

	render() {
		const {url, fields, messages} = this.state;
		return (
			<MailchimpBookingForm
				action={url}
				fields={fields}
				messages={messages}
			/>
		);
	}
}

import React from "react";
import jsonp from "jsonp";
import PropTypes from 'prop-types';

class MailchimpBookingForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			DATE: new Date().toLocaleDateString('ru'),
			TIMESLOT: '11:00 - 11:30',
			EMAIL: ''
		};
	}

	handleSubmit(evt) {
		evt.preventDefault();
		const {fields, action} = this.props;
		const values = fields.map(field => {
			return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
		}).join("&");
		const path = `${action}&${values}`;
		const url = path.replace('/post?', '/post-json?');
		const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
		const email = this.state['EMAIL'];
		(!regex.test(email)) ? this.setState({status: "empty"}) : this.sendData(url);
	};

	sendData(url) {
		this.setState({status: "sending"});
		jsonp(url, {param: "c"}, (err, data) => {
			if (data.msg.includes("already subscribed")) {
				this.setState({status: 'duplicate'});
			} else if (err) {
				this.setState({status: 'error'});
			} else if (data.result !== 'success') {
				this.setState({status: 'error'});
			} else {
				this.setState({status: 'success'});
			}
		});
	}



	render() {
		const {fields, styles, className, buttonClassName} = this.props;
		const messages = {
			...MailchimpBookingForm.defaultProps.messages,
			...this.props.messages
		}
		const {status} = this.state;
		const getInput = this.getInput

		return (
			<form onSubmit={this.handleSubmit.bind(this)} className={className}>
				<div className="bg"/>
				<div className="fields">
					{fields.map(field => (
						<div className='field' key={field.name}>
							<div className='label'>
								{field.label}
							</div>
							{getInput(field)}
						</div>
					))}
					<div className="field">
						<div className='label'/>
						<button
							disabled={status === "sending" || status === "success"}
							type="submit"
							className={buttonClassName}
						>
							{messages.button}
						</button>
					</div>
				</div>
				<div className='msg-alert'>
					{status === "sending" && <p style={styles.sendingMsg}>{messages.sending}</p>}
					{status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
					{status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
					{status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
					{status === "error" && <p style={styles.errorMsg}>{messages.error}</p>}
				</div>
			</form>
		);
	}

	getInput = (field) => {
		switch (field.type) {
			case 'futureDateInput': {
				const {type, days, ...otherFieldProps} = field
				let day = 60 * 60 * 24 * 1000;
				let now = new Date()
				const options = []

				for (let i = 0; i < days; i++) {
					options.push(new Date(now.getTime() + day * i))
				}

				return (
					<div className='select-wrapper'>
						<select
							{...otherFieldProps}
							onChange={({target}) => this.setState({[field.name]: target.value})}
							value={this.state[field.name]}
						>
							{options.map((date) => {
								const formattedDate = date.toLocaleDateString('ru')
								return (
									<option value={formattedDate} key={formattedDate}>
										{formattedDate}
									</option>
								)
							})}
						</select>
					</div>
				)
			}

			case 'timeslotInput': {
				const { type, start, end, ...otherFieldProps} = field
				const options = []

				for (let i=start; i < end; i++) {
					options.push(
						`${i}:00 - ${i}:30`
					)
					options.push(
						`${i}:30 - ${i+1}:00`
					)
				}

				return (
					<div className='select-wrapper'>
						<select
							{...otherFieldProps}
							onChange={({target}) => this.setState({[field.name]: target.value})}
							value={this.state[field.name]}
						>
							{options.map(timeslot => {
								return (
									<option value={timeslot} key={timeslot}>
										{timeslot}
									</option>
								)
							})}
						</select>
					</div>
				)
			}

			default: {
				return (
					<input
						{...field}
						key={field.name}
						onChange={({target}) => this.setState({[field.name]: target.value})}
						value={this.state[field.name]}
					/>
				)
			}
		}
	}
}

MailchimpBookingForm.defaultProps = {
	messages: {
		sending: "Sending...",
		success: "You have successfully booked your visit. See you in the Majlis!",
		error: "An unexpected internal error has occurred.",
		empty: "You must write an e-mail.",
		duplicate: "You have successfully booked your visit. See you in the Majlis!",
		button: "OK"
	},
	buttonClassName: "",
	styles: {
		sendingMsg: {
			color: "#111111"
		},
		successMsg: {
			color: "#111111"
		},
		duplicateMsg: {
			color: "#111111"
		},
		errorMsg: {
			color: "#111111"
		}
	}
};

MailchimpBookingForm.propTypes = {
	action: PropTypes.string,
	messages: PropTypes.object,
	fields: PropTypes.array,
	styles: PropTypes.object,
	className: PropTypes.string,
	buttonClassName: PropTypes.string
};
