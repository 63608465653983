import React from "react";
import ReactDOM from "react-dom";
import LazyLoad from "vanilla-lazyload";
import Swipe from "./methods/swipe";
import Swiper from "./methods/swiper";
import SubscribeMC from "./components/subscribe_mc.jsx";
import BookMC from "./components/book_mc.jsx";

import "./stylesheets/index.scss";

document.addEventListener("turbolinks:load", () => {
  document.lazyLoadInstance = new LazyLoad({
    elements_selector: ".lz-img",
    load_delay: 600,
  });

  const headerMain = document.querySelector(".header-main");
  const headerOverlay = document.querySelector(".header-main .overlay");
  const iconClose = document.querySelector(".icon-close");
  const menuButtonMobile = document.querySelector(
    ".header-main .menu-button-mobile"
  );
  if (headerMain) {
    menuButtonMobile.addEventListener("click", () => {
      headerMain.classList.add("active");
    });
    iconClose.addEventListener("click", () => {
      headerMain.classList.remove("active");
    });
    headerOverlay.addEventListener("click", () => {
      headerMain.classList.remove("active");
    });
  }

  const anchorLinks = document.querySelectorAll("a.anchor");
  anchorLinks.forEach((trigger) => {
    if (document.location.pathname !== "/") {
      headerMain.classList.remove("active");
      return null;
    }

    trigger.onclick = function (e) {
      e.preventDefault();
      let hash = this.getAttribute("href").substring(1);
      let target = document.querySelector(hash);
      let headerOffset = 0;
      let elementPosition = target.offsetTop;
      let offsetPosition = elementPosition - headerOffset;

      if (headerMain) {
        headerMain.classList.remove("active");
      }

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    };
  });

  const profileInfoLinks = document.querySelectorAll(".swiper-slide .profile");
  const profileInfoModal = document.getElementById("profile-info-container");
  const profileInfoClose = document.getElementById("profile-info-close");
  if (profileInfoLinks.length > 0) {
    profileInfoLinks.forEach((link) => {
      link.onclick = function (e) {
        let content = this;
        let target = profileInfoModal.querySelector(".profile-info-wrapper");

        target.innerHTML = content.innerHTML;
        profileInfoModal.classList.remove("hidden");
        document.body.classList.add("with-modal");
      };
    });
    profileInfoClose.addEventListener("click", () => {
      document.body.classList.remove("with-modal");
      profileInfoModal.classList.add("hidden");
    });
  }

  const accordions = document.querySelectorAll(".accordion");
  accordions.forEach((accordion) => {
    let accordionHeader = accordion.querySelector(".accordion-header");
    let body = accordionHeader.parentNode;

    accordionHeader.onclick = function (e) {
      if (body.classList.contains("collapsed")) {
        body.classList.remove("collapsed");
      } else {
        body.classList.add("collapsed");
      }
    };
  });

  const player = document.querySelector(".video-wrapper");
  if (player) {
    const video = player.querySelector(".video video");
    const playButton = player.querySelector(".play-button");

    playButton.addEventListener("click", () => {
      if (video.paused) {
        playButton.classList.add("hidden");
        video.play();
      }
    });
  }

  if (document.querySelector(".swiper-container-exhibition")) {
    const mySwiper = new Swiper(".swiper-container-exhibition", {
      speed: 400,
      spaceBetween: 7.5,
      navigation: {
        nextEl: ".swiper-button-next-exhibition",
        prevEl: ".swiper-button-prev-exhibition",
      },
      breakpoints: {
        768: {
          spaceBetween: 20,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  if (document.querySelector(".swiper-container-garden")) {
    const mySwiper = new Swiper(".swiper-container-garden", {
      speed: 400,
      spaceBetween: 7.5,
      navigation: {
        nextEl: ".swiper-button-next-garden",
        prevEl: ".swiper-button-prev-garden",
      },
      breakpoints: {
        768: {
          spaceBetween: 20,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  if (document.querySelector(".swiper-container-press")) {
    const mySwiper = new Swiper(".swiper-container-press", {
      speed: 400,
      spaceBetween: 15,
      navigation: {
        nextEl: ".swiper-button-next-press",
        prevEl: ".swiper-button-prev-press",
      },
      breakpoints: {
        768: {
          spaceBetween: 0,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  if (document.querySelector(".swiper-container-team")) {
    const mySwiper = new Swiper(".swiper-container-team", {
      speed: 400,
      spaceBetween: 45,
      navigation: {
        nextEl: ".swiper-button-next-team",
        prevEl: ".swiper-button-prev-team",
      },
      breakpoints: {
        768: {
          spaceBetween: 90,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  if (document.querySelector(".swiper-container-artefacts")) {
    const mySwiper = new Swiper(".swiper-container-artefacts", {
      slidesPerView: "auto",
      speed: 400,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next-artefacts",
        prevEl: ".swiper-button-prev-artefacts",
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  if (document.querySelector(".swiper-container-documentation")) {
    const mySwiper = new Swiper(".swiper-container-documentation", {
      slidesPerView: "auto",
      speed: 400,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next-documentation",
        prevEl: ".swiper-button-prev-documentation",
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  if (document.querySelector(".swiper-container-textiles")) {
    const mySwiper = new Swiper(".swiper-container-textiles", {
      slidesPerView: "auto",
      speed: 400,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next-textiles",
        prevEl: ".swiper-button-prev-textiles",
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    });

    mySwiper.on("transitionStart", () => {
      document.lazyLoadInstance.update();
    });
  }

  const subscribeMC = document.getElementById("subscribe-mc");
  if (subscribeMC) {
    ReactDOM.render(<SubscribeMC />, subscribeMC);
  }

  const bookMC = document.getElementById("book-mc");
  if (bookMC) {
    ReactDOM.render(<BookMC />, bookMC);
  }
});
